import '../css/EmailChk.scss';
import React, { useEffect, useRef, useState} from 'react';
import { FiAlertTriangle } from "react-icons/fi";
import axios from 'axios';
import common from '../common';
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const EmailChk = () => {

    const { pToast, useAlert, useModal, useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pModal, ModalComponent} = useModal();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    const navigate = useNavigate(); // useNavigate 훅 사용

    const [status, setStatus] = useState(null);
    const [mailHi, setmailHi] = useState(null); // API 결과값 저장 ('2', '3' 등)

    const params = new URLSearchParams(window.location.search);
    const fetchedCode = params.get('code'); // URL에서 code 추출

    useEffect(() => {

        const fetchCode = async () => {

            if(fetchedCode === null){
                navigate('/');
            }
            try {
                pLoadingOn();
                const response = await axios.post(`${API_BASE_URL}/mail_feedback/chk_code`, {
                    code: fetchedCode,
                });
                if (response.data.msg==='NODATA'){
                    navigate('/');
                }
                if (response.data.result === 't') {
                    const data = response.data.data[0].status;
                    setStatus(data); // 결과값 '2' 또는 '3' 저장
                    setmailHi(response.data.data[0].mail)
                } else {
                    pAlert(response.data.msg);
                }
            } catch (error) {
                console.error('Error fetching code or posting data:', error);
            } finally {
                pLoadingOff();
            }
        };

        
        fetchCode();
    }, []);

    const handleButtonClick = async () => {


        pConfirm(
            '이메일 수신 거부',
            <>
                <div>이메일 수신을 거부하시겠습니까?</div>
            </>,
            '400px',
            'auto',
            async () => {
                try {
            
                    pLoadingOn();
                    const response = await axios.post(`${API_BASE_URL}/mail_feedback/up_code`, {
                        code: fetchedCode,
                    });
        
                    if (response.data.result === 't') {
                        pAlert('이메일 수신거부가 완료되었습니다.');
                        setTimeout(() => {
                            navigate('/');
                        }, 5000);
                    } else {
                        pAlert(response.data.msg);
                    }
                } catch (error) {
                    pAlert('실패');
                } finally {
                    pLoadingOff();
                }
            }
        );
        
    };

    return (
        <>
            <ModalComponent/>
            <AlertComponent/>
            <ConfirmComponent/>
            <LoadingComponent/>
            <div className="emailChk_container">
                <div className="emailChk_main_box">
                    <div className="emailChk_main_txt">
                        <span className="emailChk_main_txt_highlight">
                            <span>이메일</span>
                        </span>
                        <span>수신 거부</span>
                    </div>
                    <div className="emailChk_sub_txt">
                        <span>(To unsubscribe e-mail, click on the "<b>이메일 수신 거부</b>" button)</span>
                    </div>

                    <div className="emailChk_content_box">
                        <span className='emailChk_content_icon'><FiAlertTriangle /></span>
                        <span className='emailChk_content_txt'>{mailHi} 님 안녕하세요.</span><br />
                        <span className='emailChk_content_txt'>이메일 수신을 원치 않는다면 [이메일 수신 거부] 버튼을 눌러주세요.</span>
                    </div>

                    <div className="emailChk_btn_box">

                        {status === 2 ? (
                            <>
                                <p>이미 수신이 거부되었습니다.</p>
                                <button className='emailChk_h_btn' onClick={()=>{navigate('/')}}>홈으로</button>
                            </>
                        ):(
                            <>
                                <div className="emailChk_btn_box">
                                    {/* <button className="emailChk_n_btn" onClick={()=>{navigate('/')}}>
                                    아니오
                                    </button> */}
                                    <button className="emailChk_y_btn" onClick={() => handleButtonClick()}>
                                    이메일 수신 거부
                                    </button>
                                </div>
                            </>
                        )} 
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmailChk;
