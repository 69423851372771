// src/common.js
// 토스터/모달/컨펌/알럿/로딩/버튼/인풋/셀렉/라디오/체크박스/달력

//버튼 , 인풋은 css같음
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import Modal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarDay } from 'react-icons/fa';
import { ko } from 'date-fns/locale';


// 사용할때 common 함수 지정
// const { pToast, pToastSuccess, pToastError, pToastWarning, pToastInfo, useAlert, useModal, useConfirm, useLoading, pChkbox, pRadio, useDatePicker } = common();

// 알럿,모달,컨펌창에 content나 title을 함수로 적용할때 함수 지정
// function content (){
//     return (
//         <div>
//             <span>이렇게 컨텐츠로도 넣어도됨😁</span>
//         </div>
//     );
// }


Modal.setAppElement('#root'); // 모달에 대한 루트 엘리먼트 설정

//모달 스타일
const modalCustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)', // 모달 뒤의 오버레이 색상 및 투명도 설정
        zIndex: '101'
    }
};

//컨펌창 스타일
const confirmCustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        // width: '300px', // 모달의 너비 설정 > 이건 사용자가 직접 설정해서 필요없을듯
        height: '200px', // 모달의 높이 설정
        overflow: 'auto', // 내용이 넘칠 경우 스크롤바 추가
        borderRadius: '10px', // 모달의 모서리 둥글기 설정
        padding: '20px', // 내부 여백 설정
        boxSizing: 'border-box', // border-box 모델 적용으로 padding 포함한 width, height 설정
        // 다른 스타일 속성 추가 가능...
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)', // 모달 뒤의 오버레이 색상 및 투명도 설정
        zIndex: '100' // 모달 뒤의 오버레이 색상 및 투명도 설정
    }
};

const toastList = new Set();
const MAX_TOAST = 1;

function common() {

    //복사기능
    //기본모양
    //<Button className='copy_box' onClick={() => pClipboard(selectedData.code)}><span className='f_500 f_bigger'>{selectedData.code}</span><i className="pi pi-copy"></i></Button>
    const pClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            pToast('복사 완료!');
        }).catch(err => {

        });
    };

    // 토스터 함수
    const pToast = (msg) => {
        if (toastList.size >= MAX_TOAST) {
            // 가장 오래된 토스트를 닫기
            const firstToastId = toastList.values().next().value;
            toast.dismiss(firstToastId);
            toastList.delete(firstToastId);
        }

        // 새로운 토스트 추가
        const id = toast(msg, {
            onClose: () => {
                toastList.delete(id);
            },
        });
        toastList.add(id);
    };
    // 기본 토스터 사용법
    // <button className='tmp_btn' onClick={() => pToast('기본형태')}>기본토스트</button>

    // 알럿 훅
    function useAlert() {
        const [alertIsOpen, setIsOpen] = useState(false);
        const [alertContent, setAlertContent] = useState('');
        const [alertWidth, setAlertWidth] = useState('300px'); // 너비 상태

        function pAlert(content, width) {
            setAlertContent(content);
            if (width) setAlertWidth(width);
            setIsOpen(true);
        }

        function closeAlert() {
            setIsOpen(false);
        }

        const AlertComponent = () => (
            <Modal
                isOpen={alertIsOpen}
                onRequestClose={closeAlert}
                style={{
                    ...modalCustomStyles,
                    content: {
                        ...modalCustomStyles.content,
                        width: alertWidth // 너비 사용
                    }
                }}
                contentLabel="Alert"
            >
                <h2 className='p_alertTitleStyle'>알림</h2>

                {/* <div className='p_alertContentStyle'>{alertContent}</div> */}
                <div
                    className='p_alertContentStyle'
                    dangerouslySetInnerHTML={{ __html: alertContent }}
                />
                <div className='p_modalCloseBox'>
                    <button className='p_closeBottomStyle' onClick={closeAlert}>확인</button>
                </div>
            </Modal>
        );

        return { pAlert, closeAlert, AlertComponent, alertIsOpen };
    }

    // 알럿 사용법
    // 1. 훅 지정 = const { pAlert, AlertComponent} = useAlert(); // 훅 사용

    // 2. return에 컴포넌트 설정 = <AlertComponent />

    // 3-1. 로딩있는버전 =  const handleAlertWithLoading = () => {
    //     pLoadingOn();
    //     setTimeout(() => {
    //         pAlert('내용');
    //         pLoadingOff();
    //     }, 1000); //  후 로딩 종료 | setTimeout은 지금은 알럿밖에 없어서 임시로 작성, 오래 걸리는 스크립트는 안해도될듯?
    // };

    // 4-1. 버튼에 알럿설정 (로딩O) = <button className='tmp_btn' onClick={() => handleAlertWithLoading()}>알럿열기 (로딩O)</button>

    // 4-2. 버튼에 알럿설정 (로딩X) = <button className='tmp_btn' onClick={() => pAlert('내용')}>알럿열기 (로딩X)</button>

    // 모달 훅
    function useModal() {
        const [modalIsOpen, setIsOpen] = useState(false);
        const [modalContent, setModalContent] = useState('');
        const [modalTitle, setModalTitle] = useState('');
        const [modalWidth, setModalWidth] = useState('300px'); // 너비 상태
        const [modalHeight, setModalHeight] = useState('auto'); // 높이 상태

        function pModal(title, content, width, height) {
            setModalTitle(title);
            setModalContent(content);
            if (width) setModalWidth(width);
            if (height) setModalHeight(height);
            setIsOpen(true);
        }

        function closeModal() {
            setIsOpen(false);
        }

        const ModalComponent = () => (
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{
                    ...modalCustomStyles,
                    content: {
                        ...modalCustomStyles.content,
                        width: modalWidth, // 너비 사용
                        height: modalHeight // 높이 사용
                    }
                }}
                contentLabel="Alert Modal"
            >
                <h2 className='p_modalTitleStyle'>{modalTitle}</h2>
                <div className='p_modalContentStyle'>{modalContent}</div>
                <button className='p_closeButtonStyle' onClick={closeModal}>&times;</button>
                <div className='p_modalCloseBox'>
                    <button className='p_closeBottomStyle' onClick={closeModal}>확인</button>
                </div>
            </Modal>
        );

        return { pModal, closeModal, ModalComponent, modalIsOpen };
    }

    // 모달 사용법
    // 1. 훅 지정 = const { pModal, ModalComponent} = useModal(); // 훅 사용

    // 2. return에 컴포넌트 설정 = <ModalComponent />

    // 3. 버튼에 모달 설정

    // {/* 모달 파람값 : 1.제목 / 2. 내용 / 3. width값 */}
    // <button className='tmp_btn' onClick={() => pModal(content,'내용','400px')}>모달열기</button>

    // 컨펌 훅
    function useConfirm() {
        const [confirmIsOpen, confirmSetIsOpen] = useState(false);
        const [confirmContent, setConfirmContent] = useState('');
        const [confirmTitle, setConfirmTitle] = useState('');
        const [confirmWidth, setConfirmWidth] = useState('300px'); // 너비 상태
        const [confirmHeight, setConfirmHeight] = useState('300px'); // 높이 상태
        const [onConfirm, setOnConfirm] = useState(() => () => { }); // 확인 버튼 클릭 시 실행할 함수 상태
        const [confirmBtnText, setConfirmBtnText] = useState('확인'); // 확인 버튼 텍스트
        const [cancelBtnText, setCancelBtnText] = useState('취소'); // 취소 버튼 텍스트

        function pConfirm(title, content, width, height, confirmAction, confirmBtn = '확인', cancelBtn = '취소') {
            if (confirmIsOpen) return;
            setConfirmTitle(title);
            setConfirmContent(typeof content === 'function' ? content() : content); // content가 함수일 경우 실행 결과를 저장합니다.
            if (width) setConfirmWidth(width);
            if (height) setConfirmHeight(height);
            if (confirmAction) setOnConfirm(() => confirmAction); // 확인 액션 설정
            setConfirmBtnText(confirmBtn); // 확인 버튼 텍스트 설정
            setCancelBtnText(cancelBtn); // 취소 버튼 텍스트 설정
            confirmSetIsOpen(true);
        }

        function closeConfirm() {
            confirmSetIsOpen(false);
        }

        const ConfirmComponent = () => (
            <Modal
                isOpen={confirmIsOpen}
                onRequestClose={closeConfirm}
                style={{
                    ...confirmCustomStyles,
                    content: {
                        ...confirmCustomStyles.content,
                        width: confirmWidth, // 너비 사용
                        height: confirmHeight // 높이 사용
                    }
                }}
                contentLabel="Alert Modal"
            >
                <h2 className='p_confirmTitleStyle'>{confirmTitle}</h2>
                <div className='p_confirmContentStyle'>{confirmContent}</div>
                <button className='p_confirmCloseButtonStyle' onClick={closeConfirm}>&times;</button>
                <div className='p_confirmDisplayFlex'>
                    <button className='p_confirmCheckButton' onClick={() => { onConfirm(); closeConfirm(); }}>{confirmBtnText}</button>
                    <button className='p_confirmCloseButton' onClick={closeConfirm}>{cancelBtnText}</button>
                </div>
            </Modal>
        );

        return { pConfirm, closeConfirm, ConfirmComponent };
    }

    // 컨펌창 사용법
    // 1. 훅 지정 = const { pConfirm, ConfirmComponent} = useConfirm(); // 훅 사용

    // 2. return에 컴포넌트 설정 = <ConfirmComponent/>

    // 3. 확인 버튼 눌렀을때 함수 설정
    // function tmpFunction(){
    //     console.log('확인을 눌렀을때 함수 실행');
    // }

    // 4. 버튼에 모달 설정 
    // {/* 컨펌 파람값 : 1. 제목 / 2. 내용 / 3.width / 4.height / 5.확인버튼 함수 */}
    // <button className='tmp_btn' onClick={() => pConfirm('제목',content,'500px','auto',tmpFunction,확인버튼문구,취소버튼문구)}>컨펌열기</button>

    // 로딩 훅
    function useLoading() {
        const [loading, setLoading] = useState(false);

        function pLoadingOn() {
            setLoading(true);
        }

        function pLoadingOff() {
            setLoading(false);
        }

        const LoadingComponent = () => (
            loading && (
                <div className='p_loadingOverlay'>
                    <ClipLoader color="#000" loading={loading} />
                </div>
            )
        );

        return { pLoadingOn, pLoadingOff, LoadingComponent, loading };
    }

    // 로딩 사용법

    // 1. 훅 지정 = const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    // 2. 사용 함수에 설정
    // const handleAlertWithLoading = () => {
    //     pLoadingOn();
    //     setTimeout(() => {
    //         pAlert('하위');
    //         pLoadingOff();
    //     }, 1000); 
    // };

    function pChkbox(element) {
        if (element.classList.contains('p_chkbox')) {
            var chkboxClassName = element.classList[0];
            var chkboxAddClassName = chkboxClassName + '_s';

            if (element.classList.contains(chkboxAddClassName)) {
                element.classList.remove(chkboxAddClassName);
            } else {
                element.classList.add(chkboxAddClassName);
            }
        } else {
            var parentElement = element.closest('.p_chkbox');
            if (parentElement) {
                pChkbox(parentElement);
            }
        }
    }

    // {/* 체크박스 사용 예시 */}
    // <div className='p_tmp_box'>
    //     <label className="p_chkbox">
    //         <input type="checkbox" onClick={(e) => pChkbox(e.target.parentElement)} /> 체크박스1
    //     </label>
    //     <label className="p_chkbox">
    //         <input type="checkbox" onClick={(e) => pChkbox(e.target.parentElement)} /> 체크박스2
    //     </label>
    //     <label className="p_chkbox">
    //         <input type="checkbox" onClick={(e) => pChkbox(e.target.parentElement)} /> 체크박스3
    //     </label>
    // </div>

    function pRadio(element) {
        if (element.classList.contains('p_radio')) {
            var radioClassName = element.classList[0];
            var radioAddClassName = radioClassName + '_s';

            var siblings = Array.from(element.parentNode.children).filter((sibling) => sibling !== element);

            siblings.forEach((sibling) => {
                sibling.classList.remove(radioAddClassName);
            });

            element.classList.add(radioAddClassName);
        } else {
            var parentElement = element.closest('.p_radio');
            if (parentElement) {
                pRadio(parentElement);
            }
        }
    }

    // {/* 라디오버튼 사용 예시 */}
    // <div className="p_tmp_box">
    //     <label className="p_radio">
    //         <input type="radio" onClick={(e) => pRadio(e.target.parentElement)} /> 라디오1
    //     </label>
    //     <label className="p_radio">
    //         <input type="radio" onClick={(e) => pRadio(e.target.parentElement)} /> 라디오2
    //     </label>
    // </div>



    // DatePicker 훅
    function useDatePicker() {
        const [startDate, setStartDate] = useState(new Date());

        const DatePickerComponent = () => (
            <div className='datePickerWrapper'>
                <DatePicker
                    locale={ko}
                    selected={startDate}
                    className="pDatePicker" // 이 클래스는 input 요소에 적용됩니다.
                    onChange={(date) => {
                        console.log(date);
                        setStartDate(date)
                    }}
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <FaCalendarDay className="calendarIcon" />
            </div>
        );

        return { startDate, setStartDate, DatePickerComponent };
    }

    function useDatePickerMonth() {
        const [startMonth, setStartMonth] = useState(new Date());

        const DatePickerMonthComponent = () => (
            <>
                <div className='datePickerWrapper'>
                    <DatePicker
                        locale={ko}
                        selected={startMonth}
                        className="pDatePickerMonth" // 이 클래스는 input 요소에 적용됩니다.
                        onChange={(date) => {
                            console.log(date);
                            setStartMonth(date)
                        }}
                        dateFormat="yyyy-MM"
                        showMonthYearPicker
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                    <FaCalendarDay className="calendarIcon" />
                </div>
            </>
        );

        return { startMonth, setStartMonth, DatePickerMonthComponent };
    }



    // 데이트피커 사용법

    // 1. 훅 지정 = const { DatePickerComponent } = useDatePicker();

    // 2. return에 컴포넌트 설정 = <DatePickerComponent className="p_datePicker" />




    return {
        pToast,
        useAlert,
        useModal,
        useConfirm,
        useLoading,
        pChkbox,
        pRadio,
        useDatePicker,
        useDatePickerMonth,
    };
}

export default common;