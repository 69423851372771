import React, { useEffect, useRef, useState, useCallback } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    const svgRef_1 = useRef(null);


    useEffect(() => {
        const svgElements = [
            svgRef_1.current,
        ];
        const durations = [3]; // 각 SVG의 애니메이션 지속 시간

        svgElements.forEach((svgElement, index) => {
            const paths = svgElement.querySelectorAll('path');
            const duration = durations[index]; // 해당 SVG의 지속 시간

            paths.forEach((path) => {
                const length = path.getTotalLength();
                gsap.set(path, {
                    strokeDasharray: length,
                    strokeDashoffset: length,
                });

                // ScrollTrigger 기반 애니메이션 설정
                gsap.to(path, {
                    strokeDashoffset: 0, // 선 애니메이션 끝
                    duration: duration,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: svgElement,
                        start: "top 80%", // 애니메이션 시작
                        end: "bottom 20%", // 애니메이션 끝
                        toggleActions: "play reverse play reverse", // 방향에 따라 애니메이션 재실행
                    },
                });
            });
        });
    }, [isMenuOpen]);

    return (
        <>
            <div className="header_container">
                <div className="header_wrap">
                    <a href="/" className="header_logo_box">
                        <img src="/img/b_logo.png" alt="f_logo" />
                    </a>
                    <div className="m_header_menu_box" onClick={toggleMenu}>
                        <div className="header_menu_icon_box">
                            <div className="header_menu_icon"></div>
                        </div>
                    </div>
                    <div className="header_menu_box">
                        <div className="menu_box">
                            <div className="menu">
                                <a href="/intro" >
                                    <p>회사소개</p>
                                </a>
                            </div>
                        </div>
                        <div className="menu_box">
                            <div className="menu">
                                <a href="/solution" >
                                    <p>서비스</p>
                                </a>
                            </div>
                        </div>

                        <div className="menu_box">
                            <div className="menu">
                                <a href="/contact" >
                                    <p>문의하기</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="game_box">
                        <a href="https://jellyfish.accourci.com/" target='_blank'>
                            <img src="/img/game.gif" alt="game" />
                        </a>
                    </div>
                    <div className={`menu_container ${isMenuOpen ? 'open' : ''}`}>
                        <div className="m_menu_wrap">
                            <div className="close_icon" onClick={toggleMenu}>x</div>
                            <div className="m_menu_box_box">
                                <div className="m_menu_box">
                                    <div className="menu">
                                        <a href="/" >
                                            <p>홈</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="m_menu_box">
                                    <div className="menu">
                                        <a href="/intro" >
                                            <p>회사소개</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="m_menu_box">
                                    <div className="menu">
                                        <a href="/solution" >
                                            <p>서비스</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="m_menu_box">
                                    <div className="menu">
                                        <a href="/contact" >
                                            <p>문의하기</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="m_menu_img_box">
                                <svg
                                    ref={svgRef_1}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 500 300"
                                    fill="none"
                                    preserveAspectRatio="none"
                                >

                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M347.74,129.29s-4.24-6.79-10.45-3.22c-5.05,2.9-1.71,9.7,1.2,10.43,8,2,9.24-5.07,9.24-5.07v-7.15,13.06" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M360.79,126.07s-7.19-2.76-8.92,3.38c-.62,2.19-.2,4.62,1.38,6.25,1.36,1.41,3.68,2.43,7.54.89" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M372.79,126.07s-7.19-2.76-8.92,3.38c-.62,2.19-.2,4.62,1.38,6.25,1.36,1.41,3.68,2.43,7.54.89" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M427.79,126.07s-7.19-2.76-8.92,3.38c-.62,2.19-.2,4.62,1.38,6.25,1.36,1.41,3.68,2.43,7.54.89" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M381.55,125.45s-6.2-.18-6.13,5.98,6.07,6.07,6.07,6.07c0,0,6.02.07,6-6-.01-3.93-1.89-6.1-5.95-6.05Z" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M391.64,126.45s-2.75,11.14,5.86,11.05,5.52-11.05,5.52-11.05" />
                                    <line className="cls-1" stroke="black" strokeWidth="1.5" x1="407.79" y1="126.45" x2="407.79" y2="137.5" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M407.79,130.29s2.7-3.89,7.7-3.84" />
                                    <line className="cls-1" stroke="black" strokeWidth="1.5" x1="431.28" y1="125.45" x2="431.28" y2="137.5" />
                                    <circle className="cls-1" stroke="black" strokeWidth="1.5" cx="431.5" cy="122.5" r="1" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M62.74,160.5c.14.57,1.03.49,1.76.31.54-.13,1.02-.44,1.36-.88.38-.5,1.01-1.06,1.64-.43,2.12,2.12,1.38-2.31,1-3s-.85-.93-1.61-2.27c-.99-1.73-3.49-1.56-4.37.24-1.01,2.05-2.01,1.04-3.02.96-.92-.07,0-2.28,0-2.28,1-.64-1-.64-.32-1.05.44-.26-.65-1.16-.6-2.09.06-1.11-1.97-.83-3.03-.62-.38.08-.76-.04-1.04-.3-.6-.54-1.07-.5-1.4-.3-.39.24-.9.27-1.23-.05-1.94-1.84-4.37-.13-4.36.79.01,1.03-3.01.97-3.01-1.03,0-1.1-.3-2.21-.58-2.97-.2-.57-.77-.9-1.37-.82-.88.12-2.06.04-2.06-1.21,0-2-1-3-1.59-3.56-.4-.38-1.36-.45-1.95-.45-.27,0-.53-.13-.67-.36-.93-1.49-1.87-.56-2.83.37-1.03,1-1.97,1-1.97,0,0-.83,1-2-.76-3.25-1.14-.81-2.6-.04-3.48.6-.44.32-1.05.34-1.49.01-1.07-.8-1.77-.65-2.08-.5-.16.08-.34.09-.52.05-.73-.16-2.52-.39-3.14.92-.16.33-.57.45-.9.3-1.28-.56-3.89-1.38-4.64.86-1,3-2.94-1.96-3.97-.48s-1.02-2.45-2.68-2.48c-2.35-.04-3.69.99-3.35,2.96.07.42-.94.94-2,0-.97-.86-3,0-3,0" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M68.3,159.4s.2,5.1-1.8,6.1c-1.79.89-57,1.65-67,4" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M23.5,155.26s4-2.76,5-2.76,2,1,2,2,5,0,5-1,2-3,4-2,1.12-3.08,1.12-3.08" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M42.91,155.26s6.78,2.05,4.68,4.15-2.29,3.1-1.7,3.6,4.56.5,4.08,0" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M25.5,141.5s0,4,3,1c0,0,1.31,1.83,3.66.41" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M39.65,140.75s39.85-12.25,45.85-11.25,18.57.8,27.29,6.4c8.71,5.6,22.71,3.6,26.71,2.6s53.43-2.94,81.71,11.03c0,0,65.29-9.03,100.29-6.03" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M57.1,135.64s8.4-7.14,9.4-8.14c.71-.71,3-1,4-1s5-13,8-13c0,0,2-1,5-2s2-3,3-3,9.71-2.29,9-3c-1-1,4.1-3.37,6-4,3-1,8.41-12.79,13-13,.35-.02,1,1,1,1v-1.14c0-.37.33-.66.7-.61,1.63.25,6.05.21,12.3-5.26,0,0,6-3,10,5,0,0,1.68,2.52,5.03,3.71.67.24,1.26.64,1.73,1.17,1.55,1.74,5.19,5.6,8.24,7.12,4,2,14-2,14-2,0,0,5.15.38,7.07,6.69,0,0,2.93,2.31,3.93,2.31s6,8,6,10,3,4,3,4c0,0,1,7,3,7s6,4,6,5,5,2,6,4,6.3,8.21,6.3,8.21" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M130.09,82.04s-2.59,4.46-1.59,8.46l-7,8" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M128.5,90.5s-7,4-8,4" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M128.5,87.21s-4,3.29-6,4.29" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M167.5,97.5s-4,3-4,8-8,10-8,11-12,10-12,10" />
                                    <line className="cls-1" stroke="black" strokeWidth="1.5" x1="149.42" y1="121.93" x2="141.5" y2="122.5" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M156.14,115.63s-6.64,3.87-9.64,2.87" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M161.45,110.31s-6.31,2.85-10.13,3.02" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M163.62,104.05s-7.12,4.45-9.12,4.45" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M164.82,100.68s-8.32,2.82-9.32,3.37" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M135.51,94.63s-1.01,6.87-2.01,8.87c-.63,1.26,5,7,5,10" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M149.42,104.57l-4.59-2.18s-3.33-1.89-3.33-3.89" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M117.26,113.5s-5.76-11-4.6-11.11,0-3.89,0-3.89l2.91-4.63c.26-.42.36-.92.28-1.41l-.34-1.95s-6.28,8.78-11.14,11.89" />
                                    <line className="cls-1" stroke="black" strokeWidth="1.5" x1="116.4" y1="111.82" x2="112.51" y2="115.63" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M114.45,107.83s-4.95,6.67-6.13,5.5" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M113.04,104.57s-6.54,3.93-7.65,3.26" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M112.66,98.5s-9.16,7-9.45,5.57" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M125.79,115.63s.08.65.22,1.52c.28,1.74.49,3.35,1.49,4.35.71.71-5,10.55-5,10.78s-5.24,5.74-5.24,5.74" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M126.01,117.15s-4.51,2.35-5.51,2.36" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M126.54,119.7s-7.04,3.8-8.04,3.8" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M126.54,124.48s-9.04,4.02-11.04,3.02" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M123.63,130.1s-8.23.4-9.18.9" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M120.5,134.5c-1.13-.17-9.91-.14-9-3" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M142.71,135.16s-13.03,2.66-12.62,0" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M168.5,112.5s1.45,4.33,1.22,6.17-4.73,3.97.02,7.4,5.43,3.43,5.59,5.43" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M180.06,126.07l7.61,5.39c1.45,1.03,2.55,2.47,3.15,4.14l1.93,5.36" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M88.15,110.62s2.35,3.88,1.35,5.88c0,0,8.9,9.32-.55,11.16" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M92.87,121.67s-5.37,5.83-6.37,3.83" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M91.56,119.14s-3.89,3.13-6.47,3.25" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M89.5,116.5s-2.83,3.28-4.41,2.64" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M89.38,113.33s-3.78,3.44-5.83,2.3" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M46.47,143.62s20.46-9.61,26.75-7.86" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M58.02,142.56s12.9-5.02,15.19-4.54" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M62.5,145.5s5.51-4,10.76-5" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M92.87,149.53s35.47-10.57,42.55-10.3c0,0,10.08-4.73,11.08-7.73s9-2,9-.5" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M109.21,151.3s32.76-10.8,43.02-9.8" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M118.5,151.84s26-9.11,36-8.22" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M124.19,152.7s24.31-7.2,30.31-6.2" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M130.09,153.72s14.88-5.16,22.14-4.19" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M221.21,149.53s-67.31,14.67-70.51,18.32c0,0-75.51-4.66-84.2-2.35" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M222.5,186.5c0-1,3,0,4.16-1.32.19-.21.8-1.93.49-3.31-.16-.7,0-1.42.52-1.91.7-.67,1.56-1.57,1.78-2.18.03-.1.05-.19.05-.27,0-1,0-3,1-3,.55,0-.1-.6-.8-1.14-.57-.44-.58-1.28-.06-1.76.72-.66,1.42-1.54.86-2.1-1-1-4-1-5-1-2,0,.39-4.24-1-4-1.6.28-1.24-1.29-2-1-.93.36-5,2-4,2,.54,0-.08-.86-.76-1.66-.87-1.02-2.28-1.47-3.54-1.01-.4.15-.7.36-.7.68,0,1-3-1-4,0-.71.71,0,3,1,3s-1-4-4-2c-1.89,1.26-2.99,2.92-3.29,3.97-.18.61-.64,1.09-1.26,1.26-1.13.31-2.45,1.04-2.45,2.77" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M150.7,167.85s36.59,1.52,47.53,4.29c.85.21,1.52.85,1.79,1.68.24.74.48,1.71.48,2.68,0,1.62-.65,1.27.15,1.61.45.19.69.69.55,1.17-.29.97-.5,2.56,1.11,3.07.34.11.44.54.16.76-1.05.81-2.02,2.38,2.03,4.4" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M208.8,173.77s-3.3,1.73-1.3,2.73c1.2.6.6,1.56-.07,2.23s-.78,1.67-.3,2.45c.41.68,1.07,1.31,2.11,1.31,2.27,0,1.56,2.29,2.27,3,1,1,4,0,4-1" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M216.5,171.5s-4,3-1,4-3,1.57,1,2.29,3.43,2.29,4.71,0,4.29-1.29,3.29-3.29" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M218.5,190.5c2,0,2.43-1.23,2.71-2.12s3.78-4.05.53-2.47-2.24,3.59-3.24,4.59-3.07-1.39-4.12-1.61c-1.88-.39-3.88-.39-3.88,1.61" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M204.5,187.5c2,1,6,5,6,3" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M230.5,174.5s53.97,6.86,64.23,10.26c.74.24.97,1.14.48,1.73s-.98,1.39-.92,2.23c.03.48-.23.93-.63,1.19-.7.47-1.41,1.33-.16,2.58,2,2,1,2,1,3,0,.63,1.9.47,3.3.26.74-.11,1.5.26,1.8.95.18.42.46.79.89.79.83,0,3.03.69,3.19,1.49.06.31.39.5.7.49,1.85-.08,5.88-.43,5.1-1.98-.64-1.28.76-.92,1.86-.5.61.23,1.28.15,1.84-.18.5-.28,1.26-.4,2.3.3,1.32.91,2.21-.1,2.65-.88.24-.43.64-.73,1.11-.9.57-.2,1.24-.69,1.24-1.85s.65-1.31,1.21-1.24c.6.08,1.21-.08,1.64-.51.8-.78,1.76-2,.97-2.8-.43-.43-.71-1-.62-1.6.23-1.54.5-4.16-.19-4.85-1-1-1-2-1.99-4.52-.17-.45-1.01-3.48-3.06-3.91,0,0-3.05-1.21-3.79-.14-.22.31-.6.46-.98.38-1.5-.32-4.61-.76-5.19.88-.2.57-.66,1.05-1.27,1.11s-1.19.42-1.04,1.68c.31,2.52-2.67,3.5-2.68,2.51s-2.01,0-3.83,0c-2.37-.01-1.46.63-1.27,1.74.11.61-.4,1.16-1.02,1.08-1.4-.18-3.65-.25-3.65,1.15" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M237.5,147.5s42.31,10.48,21,31" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M250.5,146.5c-3.39-.33,51.53,4.26,29.38,35.14" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M245.5,146.5c-.28.59,4.65,2.44,8,3" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M254.31,149.94s7.19,2.56,8.38,3.12" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M263.5,153.5s7,3,8,5" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M272.5,159.5c-.2-.11,4.68,3.55,3,8" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M274.66,169.15s-2.16,5.35-4.16,7.83" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M389.41,155.5s0,76.77,0,79c.01,3-.01,27.36,0,29.18s-12.99,4.82-18.99.82c0,0,1-28,1-31,0-1.41-1-78-1-78h-49.22v-53h117.83s25.7,25.7,25.7,25.7l-27.3,27.3-47.87.25-19.13-.25" />
                                    <line className="cls-1" stroke="black" strokeWidth="1.5" x1="386.01" y1="158.5" x2="386.01" y2="262.57" />
                                    <polygon className="cls-1" stroke="black" strokeWidth="1.5" points="458.86 128.48 436.73 150.53 326.43 150.53 326.43 108.05 437.07 108.05 458.86 128.48" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M285.89,143.18s-.84-2.51,3.63-2.28c.32.02.72-.2.86-.49.25-.55.93-1.16,2.78-.05.37.22.87.16,1.12-.19.44-.62,1.36-1.32,3.23-.68,1.98.67,2.21-.42,2.14-1.23-.05-.56.25-1.1.75-1.35.16-.08.34-.16.54-.23.59-.2,1.05-.67,1.15-1.28.16-1.09.85-2.52,3.43-1.88,0,0,1.56,0,2.42.74.23.2.6.03.63-.28.1-1.15-2.18-3.05.93-2.48,1.04.19,4.41.17,3,3-1,2,.04-.4,2,0,1.49.31-.66-3,3-2,2.73.74-1-1,0-2,0,0,3.39-1.72,3.7,1.14" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M446.5,146.5s37.65,2,54.32,8" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M500.5,130.5s-5.64-5.17-8.82.41-3.03,1.61-5.11,2.6-3.09,3.51-4.09,4.76-3.99.23-4.99-1.77c-.84-1.69-1.35-3.54-3.76-3-.36.08-.73.07-1.09.02-1.25-.16-3.96-.33-4.85.97-.27.39-.82.42-1.17.11-1.23-1.1-3.47-2.49-4.91.4-.17.34-.41.63-.75.81-.99.53-3.09,1.43-3.69-.18" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M16.79,145.5c-1.29-1-5.33,3.06-2.81,4.03s4.1,2.16,2.81,4.06" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M5.5,150.5c.59,1.13-4.49-.16-2.24-2.08s1.69-6.69,4.47-4.81,5.11,1.25,5.44-.43" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M2.23,160.94s3.27,3.56,10.27,1.56,8.83-2.95,10.41-.47" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M320.21,194.62s3.29.88,3.29,1.88c0,.64,1.22.08,2.1-.41.54-.3,1.19-.28,1.7.07.57.39,1.29.79,1.62.63.59-.29,2.82-1.85,3.7-1.07s4.33-.58,5.11,1.1c0,0,1.35,1.32,3.06,0,1.5-1.16,2.28-1.44,3.88-.08.42.36.98.5,1.51.35.67-.18,1.74-.31,3.06.09.46.14.95.11,1.4-.05.85-.3,2.1-.79,2.4-1.13.46-.51,2.46-1.51,4.46-.51,1.7.85,1.96-1.92,1.99-3.38.01-.4.16-.78.42-1.09.8-.92,2.47-2.95,2.57-4.04.04-.41.15-.81.41-1.12,1.05-1.27-.72-3.56.63-4.83.54-.51.81-1.23.72-1.97-.1-.86-.1-1.85.37-2.1,2.38-1.22.03-4.18-1.97-4.61-.51-.11-.89-.58-.86-1.1.17-3.35-4.84-7.34-8.29-5.77-1.62.74-1.72,1.61-2.16,2-.81.71,1.74-3.34-.28-2.33-.41.2-.88.21-1.32.06-1.4-.47-4.25-1.24-4.25.27,0,2-1.52-1-2-1s-4,3-3,1c.83-1.67-2.5-.56-3.06,1.02-.18.5-.74.73-1.25.59-1.7-.46-4.39-1-3.69.39s-.64.8-1.46.33c-.45-.26-.94-.42-1.45-.49-1.31-.17-3.55-.27-3.36,1.15.27,2-1.73,1-1.73,1,0,0-3.83-.77-4.12,1.05-.1.64-.59,1.15-1.17,1.42-.99.46-2.01,1.22-.77,2.09" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M300.32,186.49s-1.18,1.69,0,2.85.31,3.22,1.74,3.19,4.44-2.03,5.44-1.03" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M309.5,185.5s2.42,0,2.91,2.17c.18.79.84,1.43,1.64,1.38.51-.04,1.06-.34,1.44-1.24,1-2.31,3,.69,4,.69s0-6,2-4" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M327.51,181.64s-.67,5.84,1.66,4.85c0,0,4.1,2.99,4.21,0" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M340.02,189.05s1.48,4.45,4.48,1.45,2.36-1.41,3.18-.21,4.12.31,4.47-1.24,1.29-4.09,3.32-4.32" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M338.5,172.5c0,1,4.47,2.96,5.73.98,1.13-1.76,4.38-1.99,5.45-.6.28.36.63.67,1.08.78,1.52.36,4.96-1.68,4.16.1-.22.5-.24,1.07,0,1.57.64,1.34.58,2.17-.09,2.65" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M389.5,200.5s105.65,27.66,111.32,30.33" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M359.13,194.64s8.37,1.86,11.37,1.86" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M132.86,166.76s-25.04,11.98,27.3,45.86c52.34,33.88,274.33,87.45,274.33,87.45" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M79.5,164.5c-2.46,3.16-61.24,66.31,39.54,135.56" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M110.81,165.59c.3.26-4.31.91-5.43,2.26" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M104.5,168.5s-5,3-6.01,6.74" />
                                    <line className="cls-1" stroke="black" strokeWidth="1.5" x1="97.52" y1="176.8" x2="94.94" y2="184.54" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M94.3,187.51s-.8,7.99,1.41,9.99" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M96.13,199.5s4.37,9,7.88,13.07" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M106.38,215.74s7.12,10.76,17.05,17.02" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M126.37,235.2s14.13,12.3,23.13,15.3" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M152.5,252.5s24.41,15.13,37.2,19.56" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M192.5,273.5s15,8,26.69,12.56" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M222.5,287.5s15.45,8.6,27.5,13.5" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M262.16,226.73s-4.8-3.09-3.47-3.45,4.11,1.55,4.75,1.9-.65-3.7-.65-3.7c0,0-.96-2.2,1.37-1.31s2.83,5.35,4.09,4.89c.96-.34.66-2.38.46-3.34-.07-.32.17-.63.5-.63.78,0,2.08.62,2.52,4.45" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M53.39,292.97s-7.13-5.26-5.04-5.69,6.22,2.8,7.17,3.4-.64-5.8-.64-5.8c0,0-1.28-3.5,2.26-1.89s3.86,8.56,5.86,7.98c1.67-.49,1.18-4.3.98-5.53-.04-.23.11-.46.35-.5,1.03-.17,3.67.1,4.02,7.11" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M446.63,279.52s5.94-4.79,4.12-5.08-5.24,2.65-6.04,3.2.33-5.01.33-5.01c0,0,.97-3.06-2.01-1.54s-2.99,7.51-4.74,7.08c-1.39-.34-1.2-3.37-1.07-4.57.03-.3-.2-.57-.5-.58-.99-.02-2.95.66-2.99,6.28" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M417.78,238.86s4.94-2.86,3.63-3.29-4.18,1.36-4.84,1.67.82-3.67.82-3.67c0,0,1.06-2.15-1.31-1.37s-3.07,5.21-4.31,4.69c-1.03-.43-.46-2.8-.24-3.59.04-.16-.05-.32-.2-.36-.67-.18-2.34-.14-3.06,4.27" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M332.78,215.86s4.94-2.86,3.63-3.29-4.18,1.36-4.84,1.67.82-3.67.82-3.67c0,0,1.06-2.15-1.31-1.37s-3.07,5.21-4.31,4.69c-.99-.41-.5-2.62-.27-3.5.06-.22-.08-.45-.31-.49-.73-.13-2.25.16-2.93,4.3" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M37.01,191.23s3.62-3.35,2.44-3.46-3.27,1.94-3.76,2.33,0-3.25,0-3.25c0,0,.49-2.02-1.37-.91s-1.61,4.97-2.75,4.78c-.91-.16-.92-2.09-.89-2.88,0-.21-.16-.39-.37-.37-.65.04-1.85.59-1.63,4.18" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M422.91,169.64s2.54-1.92,1.79-2.07-2.23,1.04-2.57,1.26.21-2.09.21-2.09c0,0,.44-1.27-.82-.67s-1.35,3.1-2.07,2.9c-.56-.16-.46-1.36-.39-1.88.02-.15-.09-.29-.24-.29-.43,0-1.2.31-1.29,2.58" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M491.91,187.64s2.54-1.92,1.79-2.07-2.23,1.04-2.57,1.26.21-2.09.21-2.09c0,0,.44-1.27-.82-.67s-1.35,3.1-2.07,2.9c-.56-.16-.46-1.36-.39-1.88.02-.15-.09-.29-.24-.29-.43,0-1.2.31-1.29,2.58" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M450.12,196.79s-2.47-2.17-1.68-2.26,2.21,1.24,2.54,1.49-.05-2.16-.05-2.16c0,0-.36-1.33.89-.63s1.15,3.29,1.91,3.14c.59-.12.58-1.35.55-1.9-.01-.17.14-.31.31-.29.44.05,1.17.48,1.09,2.75" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M473.35,265.02s-5.84-3.74-4.23-4.18,5,1.87,5.77,2.29-.8-4.49-.8-4.49c0,0-1.18-2.66,1.66-1.59s3.45,6.48,4.98,5.92c1.16-.42.8-2.86.55-4.04-.08-.4.22-.79.62-.78.95.02,2.51.78,3.05,5.39" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M12.57,243.6s-1.66-8.03,2.63-3.06c0,0,.79-7.5,2.04-4.77s2.25,9.73,1.25,7.73c0,0,1-6,2-5,0,0,1.96,3.19,1.48,5.1" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M332.3,262.56s-1.88-9.11,2.99-3.48c0,0,.89-8.51,2.31-5.41s2.56,11.04,1.42,8.77c0,0,1.13-6.81,2.27-5.67,0,0,2.22,3.62,1.68,5.78" />
                                    <path className="cls-1" stroke="black" strokeWidth="1.5" d="M159.2,194.64s-1.37-6.61,2.16-2.52c0,0,.65-6.17,1.68-3.92s1.85,8.01,1.03,6.36c0,0,.82-4.93,1.64-4.11,0,0,1.61,2.62,1.22,4.19" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;