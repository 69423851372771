
function Footer() {

    return (
        <>
            <div className="footer_container">
                <div className="footer_wrap">
                    <div className="footer_logo_box">
                        <img src="/img/b_logo.png" alt="f_logo" />
                    </div>
                    <div className="footer_text_box">
                        <span>(주)아쿠시</span>
                        <span>대표이사 : 김준모</span>
                        <span>사업자번호 : 397-88-01411</span>
                        <span>서울 영등포구 당산로 241 3층 | 연락처 : 02-2663-0435 | 이메일 : info@accourci.com</span>
                        <span>Copyright © ACCOURCI All rights reserved.</span>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Footer;